import React from 'react'
import { graphql } from 'gatsby'

import { useAppSettings } from '../hooks/useAppSettings'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../components/layout'
import CustomSVG from '../components/custom-svg'
import CardLink from '../mui-components/CardLink/CardLink'

const styles = makeStyles(theme => ({
  logoWrapper: {
    display: 'flex' // fix wrapper height
  },
  footerLogoWrapper: {
    '& svg': {
      width: '135px',
      height: '65px',
      [theme.breakpoints.up('md')]: {
        width: '180px',
        height: '185px'
      }
    }
  }
}))

export const query = graphql`
  query HomepageQuery {
    hp: sanityHomepage {
      contentSections {
        _key
        linkTo
        sectionSettings {
          sectionTitle
          sectionIcon {
            iconSource
          }
        }
      }
      footerLogo
      metaTitle
      metaDescription
    }
  }
`

const bottomLogoStyles = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignSelf: 'flex-end',
  width: '100%',
  marginTop: '20px'
}

const IndexPage = props => {
  const classes = styles()
  const { data, errors } = props
  const { appName, appLogo, bgImage } = useAppSettings()

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const title = appName
  const metaTitle = data.hp.metaTitle || title
  const metaDescription = data.hp.metaDescription

  const items = data.hp.contentSections.map(node => {
    return (
      <Grid item xs={6} key={node._key}>
        <CardLink
          black
          icon={node.sectionSettings.sectionIcon}
          iconSemiTransparent
          title={node.sectionSettings ? node.sectionSettings.sectionTitle : ''}
          to={node.linkTo}
        />
      </Grid>
    )
  })

  return (
    <Layout
      title={appLogo ? <CustomSVG content={appLogo} className={classes.logoWrapper} /> : appName}
      bgImage={bgImage}
      {...props}
    >
      <SEO title={metaTitle} description={metaDescription} />
      <Grid container spacing={3}>
        {items}
      </Grid>

      {props.data.hp && props.data.hp.footerLogo ? (
        <div style={bottomLogoStyles}>
          {props.data.hp.footerLogo ? (
            <CustomSVG content={props.data.hp.footerLogo} className={classes.footerLogoWrapper} />
          ) : null}
        </div>
      ) : null}
    </Layout>
  )
}

export default IndexPage
